<template>
  <b-card
    v-if="Object.keys(servicer).length"
    class="blog-edit-wrapper"
  >

    <!-- form -->
    <b-form class="mt-2" @submit.prevent="save">
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Imię"
            label-for="blog-edit-title"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-title"
              v-model="servicer.firstname"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Nazwisko"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="servicer.lastname"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Ulica"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="servicer.address"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Kod pocztowy"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="servicer.postcode"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Miasto"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="servicer.city"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Województwo"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <select class="form-control" v-model="servicer.state" style="width: 100%;">
              <option v-for="state in states" :value="state.name">{{state.name}}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Pierwszy numer telefonu"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="servicer.phone1"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Drugi numer telefonu"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="servicer.phone2"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="E-mail"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="servicer.email"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Firma"
            label-for="blog-edit-slug"
            class="mb-2"
          >
		    <b-form-checkbox
              id="checkbox-1"
		      v-model="servicer.is_company"
      		  name="checkbox-1"
		    >
		      Firma
		    </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4" v-if="servicer.is_company">
          <b-form-group
            label="Osoba do kontaktu"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="servicer.contact_person"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" v-if="servicer.is_company">
          <b-form-group
            label="NIP"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="servicer.vatnumber"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" v-if="servicer.is_company">
          <b-form-group
            label="Nazwa firmy"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="servicer.vatname"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="servicer.is_company">
          <b-form-group
            label="Ulica"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="servicer.vataddress"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="servicer.is_company">
          <b-form-group
            label="Kod pocztowy"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="servicer.vatpostcode"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="servicer.is_company">
          <b-form-group
            label="Miasto"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input
              id="blog-edit-slug"
              v-model="servicer.vatcity"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="servicer.is_company">
          <b-form-group
            label="Województwo"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <select class="form-control" v-model="servicer.vatstate" style="width: 100%;">
              <option v-for="state in states" :value="state.name">{{state.name}}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Uwagi dodatkowe"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-textarea
              id="blog-edit-slug"
              v-model="servicer.note"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mr-1"
          >
            Zapisz zmiany
          </b-button>
          <b-button
            :to="{ name: 'apps-clients' }"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Anuluj
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      :to="{ name: 'apps-filter-client', query: { client_id: servicer.id } }"
      variant="primary"
      class="mt-1"
    >
      Dodaj urządzenie
    </b-button>
    <b-col md="12">
    	<span style="display: inline-block; font-size: 2rem; color: #000; padding-bottom: 0.5rem; margin-bottom: 1rem; border-bottom: 2px solid #000; width: 100%;">Aktywne urządzenia</span>
    </b-col>
    <b-table
      v-if="items_active.length > 0"
      :items="items_active"
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="Brak danych serwisów"
      class="position-relative mt-2"
    >

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-filter', params: { id: data.item.id } }" v-b-tooltip.hover title="Edytuj urządzenie">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button v-if="role_id == 1" variant="primary" class="mr-1" @click="deleteFilter(data.item.id)" v-b-tooltip.hover title="Usuń urządzenie">
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-realizedservice-filter', query: { id_filter: data.item.id } }" v-b-tooltip.hover title="Nowy serwis">
              <feather-icon icon="SettingsIcon" />
            </b-button>
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-reparatur-filter', query: { id_filter: data.item.id } }" v-b-tooltip.hover title="Nowa naprawa">
              <feather-icon icon="ToolIcon" />
            </b-button>
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-history', query: { id_filter: data.item.id, id_client: client_id } }" v-b-tooltip.hover title="Historia">
              <feather-icon icon="ListIcon" />
            </b-button>
        </div>
      </template>

    </b-table>
    <b-col md="12">
    	<span style="display: inline-block; font-size: 2rem; color: #000; padding-bottom: 0.5rem; margin-bottom: 1rem; border-bottom: 2px solid #000; width: 100%;">Nieaktywne urządzenia</span>
    </b-col>
    <b-table
      v-if="items_deactive.length > 0"
      :items="items_deactive"
      :fields="fields"
      primary-key="id"
      show-empty
      empty-text="Brak danych serwisów"
      class="position-relative mt-2"
    >

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-filter', params: { id: data.item.id } }" v-b-tooltip.hover title="Edytuj urządzenie">
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button v-if="role_id == 1" variant="primary" class="mr-1" @click="deleteFilter(data.item.id)" v-b-tooltip.hover title="Usuń urządzenie">
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-button variant="primary" class="mr-1" :to="{ name: 'apps-history', query: { id_filter: data.item.id, id_client: client_id } }" v-b-tooltip.hover title="Historia">
              <feather-icon icon="ListIcon" />
            </b-button>
        </div>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BTable, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    quillEditor,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      role_id: localStorage.getItem('id'),
      servicer: {
        id: 0,
        firstname: '',
        lastname: '',
        address: '',
        state: '',
        city: '',
        postcode: '',
        vatnumber: '',
        vatname: '',
        vataddress: '',
        vatstate: '',
        vatcity: '',
        vatpostcode: '',
        phone1: '',
        phone2: '',
        note: '',
        email: '',
        contact_person: '',
        filters: [],
        is_company: false
      },
      snowOption: {
        theme: 'snow',
      },
      states: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Nazwa',
          sortable: true,
        },
        {
          key: 'service_date',
          label: 'Data następnego serwisu',
          sortable: true,
        },
        {
          key: 'lastServiceDate',
          label: 'Data poprzedniego serwisu'
        },
        {
          key: 'installation_date',
          label: 'Data instalacji urządzenia',
          sortable: false,
        },
        {
          key: 'actions',
          label: 'akcje',
        },
      ],
      items_active: [],
      items_deactive: [],
      client_id: 0,
    }
  },
  created() {
    this.client_id = this.$route.params.id ?? 0
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
    if (this.client_id > 0) {
      axios.get(`${process.env.VUE_APP_API_URL}Client/${this.client_id}`, config).then(response => {
        this.servicer = response.data
        var tempArray = []
        for(var i = 0; i < this.servicer.filters.length; i++) {
  		  tempArray.push(this.servicer.filters[i].filter_id+"")
        }
        this.servicer.filters = tempArray
        this.servicer.is_company = response.data.is_company == 1 ? true : false
	    this.fetchFilters()
      }).catch(error => {
        console.log(error)
      })
    }
	axios.get(`${process.env.VUE_APP_API_URL}State`, config).then(response => {
	  this.states = response.data
	}).catch(error => {
	  console.log(error)
	})
  },
  methods: {
    fetchFilters() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
      axios.get(`${process.env.VUE_APP_API_URL}FilterClient/${this.servicer.id}/1`, config).then(response => {
        this.items_active = response.data
        response.data.map(item => {
          item.lastServiceDate = item.realized_services.sort((a,b) => {
            return new Date(b.service_date) - new Date(a.service_date)
          })[0].service_date.slice(0, 10)
        })
      }).catch(error => {
        console.log(error)
      })
      axios.get(`${process.env.VUE_APP_API_URL}FilterClient/${this.servicer.id}/0`, config).then(response => {
        this.items_deactive = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    deleteFilter(id) {
      Swal.fire({
	    title: 'Czy na pewno chcesz usunąć to urządzenie?',
	    showDenyButton: false,
	    showCancelButton: true,
	    confirmButtonText: 'Usuń',
	    confirmButtonColor: '#7367f0',
	    cancelButtonText: 'Anuluj',
	    customClass: {
	      confirmButton: 'mr-2'
	    }
	  }).then((result) => {
	    if (result.isConfirmed) {
	      axios.delete(`${process.env.VUE_APP_API_URL}Filter/${id}`, {
	        headers: {
	          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
	        },
	      }).then(() => {
	        Swal.fire(
	          'Pomyślnie usunięto urządzenie',
	          '',
	          'success',
	        )
	        this.fetchFilters()
	      }).catch(() => {
	        Swal.fire(
	          'Coś poszło nie tak!',
	          '',
	          'error',
	        )
	      })
	    }
	  })
    },
    save() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } };
      axios.post(`${process.env.VUE_APP_API_URL}Client`, this.servicer, config).then(response => {
        this.$router.push({path: '/apps/clients'})
      }).catch(error => {
      	var text = [];
      	for (const [key, value] of Object.entries(error.response.data.data)) {
		  if(value.length > 0) {
		  	text.push(value[0])
		  }
		}
        Swal.fire(
          text.join('<br/>'),
          '',
          'error',
        )
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
